// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet');"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Saira Semi Condensed", sans-serif;
  transition: all 0.5s ease;
}





.field:nth-of-type(2) {
  margin: 16px 0;
}







`, "",{"version":3,"sources":["webpack://./src/app2.js/css/with.css"],"names":[],"mappings":"AACA;EACE,+CAA+C;EAC/C,yBAAyB;AAC3B;;;;;;AAMA;EACE,cAAc;AAChB","sourcesContent":["@import url(\"https://fonts.googleapis.com/css?family=Roboto' rel='stylesheet' \");\n* {\n  font-family: \"Saira Semi Condensed\", sans-serif;\n  transition: all 0.5s ease;\n}\n\n\n\n\n\n.field:nth-of-type(2) {\n  margin: 16px 0;\n}\n\n\n\n\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
