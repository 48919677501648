// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*.modal {*/
/*  position: fixed;*/
/*  left: 0;*/
/*  top: 0;*/
/*  right: 0;*/
/*  bottom: 0;*/
/*  background-color: rgba(64, 64, 64, 0.95);*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*}*/

/*.modal-content {*/
/*  display: flex;*/
/*  flex-direction: row;*/
/*  justify-content: space-between;*/
/*  align-items: center;*/
/*  background-color: white;*/
/*  border-radius: 10px;*/
/*  width: 40%;*/
/*  padding: 10px 20px 10px 20px;*/
/*}*/

/*.modal-button {*/
/*  border-radius: 50%;*/
/*  background-color: rgb(93, 133, 93);*/
/*  color: white;*/
/*  padding: 5px 10px;*/
/*  cursor: pointer;*/
/*}*/
body {
 margin: 0;
 padding: 0;
 height:100%;
}
.App{
 color: #228b22;
 text-align: center;
}
.footer {
/* background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important; */

 position: fixed;
 width: 100%;
 bottom: 0;
 color: white;
 font-size: 25px;
}
.my-sticky-footer {
  text-align: center;
 background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important;
  color: #fff;
  padding: 20px; 
  position: fixed;
  bottom: 0;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app2.js/Components/Component-css/Downloadbutton.css"],"names":[],"mappings":"AAAA,WAAW;AACX,qBAAqB;AACrB,aAAa;AACb,YAAY;AACZ,cAAc;AACd,eAAe;AACf,8CAA8C;AAC9C,mBAAmB;AACnB,yBAAyB;AACzB,6BAA6B;AAC7B,IAAI;;AAEJ,mBAAmB;AACnB,mBAAmB;AACnB,yBAAyB;AACzB,oCAAoC;AACpC,yBAAyB;AACzB,6BAA6B;AAC7B,yBAAyB;AACzB,gBAAgB;AAChB,kCAAkC;AAClC,IAAI;;AAEJ,kBAAkB;AAClB,wBAAwB;AACxB,wCAAwC;AACxC,kBAAkB;AAClB,uBAAuB;AACvB,qBAAqB;AACrB,IAAI;AACJ;CACC,SAAS;CACT,UAAU;CACV,WAAW;AACZ;AACA;CACC,cAAc;CACd,kBAAkB;AACnB;AACA;AACA,uFAAuF;;CAEtF,eAAe;CACf,WAAW;CACX,SAAS;CACT,YAAY;CACZ,eAAe;AAChB;AACA;EACE,kBAAkB;CACnB,iFAAiF;EAChF,WAAW;EACX,aAAa;EACb,eAAe;EACf,SAAS;EACT,WAAW;AACb","sourcesContent":["/*.modal {*/\n/*  position: fixed;*/\n/*  left: 0;*/\n/*  top: 0;*/\n/*  right: 0;*/\n/*  bottom: 0;*/\n/*  background-color: rgba(64, 64, 64, 0.95);*/\n/*  display: flex;*/\n/*  align-items: center;*/\n/*  justify-content: center;*/\n/*}*/\n\n/*.modal-content {*/\n/*  display: flex;*/\n/*  flex-direction: row;*/\n/*  justify-content: space-between;*/\n/*  align-items: center;*/\n/*  background-color: white;*/\n/*  border-radius: 10px;*/\n/*  width: 40%;*/\n/*  padding: 10px 20px 10px 20px;*/\n/*}*/\n\n/*.modal-button {*/\n/*  border-radius: 50%;*/\n/*  background-color: rgb(93, 133, 93);*/\n/*  color: white;*/\n/*  padding: 5px 10px;*/\n/*  cursor: pointer;*/\n/*}*/\nbody {\n margin: 0;\n padding: 0;\n height:100%;\n}\n.App{\n color: #228b22;\n text-align: center;\n}\n.footer {\n/* background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important; */\n\n position: fixed;\n width: 100%;\n bottom: 0;\n color: white;\n font-size: 25px;\n}\n.my-sticky-footer {\n  text-align: center;\n background-image: linear-gradient(rgb(61, 128, 197), rgb(37, 61, 118)) !important;\n  color: #fff;\n  padding: 20px; \n  position: fixed;\n  bottom: 0;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
